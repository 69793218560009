import { useState } from 'react';
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './auth';

interface IFormInput {
  email: string;
  password: string;
}

const URL_BASE = 'https://codefithub.onrender.com';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.path || '/home';

  const onSubmit = handleSubmit((values) => {
    const email = values.email;
    const password = values.password;

    const configuration = {
    url: URL_BASE + '/login',
    method: 'post',
    data: {
      email,
      password
    },
    }

    axios.request(configuration)
      .then((result) => {

        if (!result.data.token) {
        setErrorMessage("Invalid credentials data token");
      }
      auth?.login(result.data._doc);

      localStorage.setItem('jwt', result.data.token);
      navigate(redirectPath, { replace: true });
      }).catch(err => {
        setErrorMessage("Invalid credentials error");
    })
  })

  return (
    <div className='flex min-h-full flex-col justify-center px-6 py-1 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>Login</h2>
      </div>
      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        {errorMessage && <p className='peer-invalid:visible text-red-700 font-light'>{errorMessage}</p>}
        <form onSubmit={onSubmit} className='space-y-6'>
<div>
            <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>Email address</label>
            <div className='mt-2'>
              <input
                {...register('email', { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ })}
                aria-invalid={errors.email ? 'true' : 'false'}
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
               {errors.email?.type === 'required' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Email is required.</p>
              )}

              {errors.email?.type === "pattern" && (
                <p className='peer-invalid:visible text-red-700 font-light'>Email not valid.</p>
              )}
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>Password</label>
            </div>
            <div className='mt-2'>
              <input
                {...register('password', { required: true })}
                aria-invalid={errors.password ? 'true' : 'false'}
                type='password'
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
               {errors.password?.type === 'required' && (
                <p className='peer-invalid:visible text-red-700 font-light'>Password is required.</p>
              )}
            </div>
          </div>
          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >Sign in</button>
          </div>

        </form>
        </div>
    </div>
  )
}

export default Login
