import axios, { AxiosResponse, AxiosError } from 'axios';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SEO from './SEO';

export type UserInfoType = {
  username: string;
  email: string;
  content: string;
};

interface IFormInput {
  userName: string;
  email: string;
  content: string;
}

const URL_BASE = 'https://codefithub.onrender.com';

const ContactForm = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<IFormInput>()
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const { t } = useTranslation();

  const onSubmit = handleSubmit((values) => {
    const username = values.userName;
    const email = values.email;
    const content = values.content;

    const configuration = {
      url: URL_BASE + '/contact/send',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
      data: {
        username,
        email,
        content
      },
    }

    axios.request(configuration).then((res: AxiosResponse) => {
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        setSuccessMessage('Message sent successfully!')
        reset({ userName: '', email: '', content: '' })
      }
    }).catch((err: AxiosError) => {
      console.log(err);
    })
    setTimeout(() => {
      setErrorMessage('');
      setSuccessMessage('');
    }, 2000);
  })

  return (
    <div className="container mx-auto">
      <SEO
        title='AnittaMendezFitness: Ejercicios,Rutinas,Circuitos,Salud,Bienestar'
        description='Descubre cómo mejorar tu salud con circuitos y rutinas de ejercicio y motivación para lograr tus objetivos de fitness.'
        image='https://anittamendezfitness.com/static/media/logo-fitness.e7fde95318d7ca1fc76f.png'
        url='https://anittamendezfitness.com/contact'
      />
      <form onSubmit={onSubmit} className='space-y-6'>
          {errorMessage && <p className='peer-invalid:visible text-red-700 font-light'>{errorMessage}</p>}
          {successMessage && <p className='peer-invalid:visible text-green-700 font-light'>{successMessage}</p>}
        <div>
          <div className='flex items-center justify-between'>
            <label className='block text-sm font-medium leading-6 text-gray-900'>{t('contact.username')}</label>
          </div>
          <div className='mt-2'>
            <input
              {...register('userName', { required: true, minLength: 3 })}
              aria-invalid={errors.userName ? 'true' : 'false'}
              className='block w-3/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            />
            {errors.userName?.type === 'required' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('contact.errors.author_name_is_required')}.</p>
            )}

            {errors.userName?.type === 'minLength' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('contact.errors.author_name_length')}.</p>
            )}
          </div>
        </div>
        <div>
          <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>{t('contact.email')}</label>
          <div className='mt-2'>
            <input
              {...register('email', { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ })}
              aria-invalid={errors.email ? 'true' : 'false'}
              className='block w-3/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            />
              {errors.email?.type === 'required' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('contact.errors.email_is_required')}.</p>
            )}

            {errors.email?.type === "pattern" && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('contact.errors.email_is_not_valid')}.</p>
            )}
          </div>
        </div>
        <div>
          <form className="w-full">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('contact.content')}</label>
            <textarea
              {...register('content', { required: true, minLength: 50 })}
              rows={4}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder={t('contact.write_your_reason_of_contact_here')}>
            </textarea>
            {errors.content?.type === 'required' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('contact.errors.content_is_required')}.</p>
            )}

            {errors.content?.type === 'minLength' && (
              <p className='peer-invalid:visible text-red-700 font-light'>{t('contact.errors.min_length')}.</p>
            )}
          </form>
        </div>
        <div>
          <button
            type='submit'
            className='flex w-3/12 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >{t('contact.send_message')}</button>
        </div>
      </form>
    </div>
    )
}

export default ContactForm
