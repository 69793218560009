import { useForm } from 'react-hook-form'
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const URL_BASE = 'https://codefithub.onrender.com';

const UploadImage = () => {
  const location = useLocation();
  const { state } = location;
  const { handleSubmit, register } = useForm()
  const url = URL_BASE + `/upload-image/${state.article._id}`
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();

    formData.append('file', data.file[0]);
    formData.append('article_id', state.article._id);
    formData.append('image', state.article.image);

    axios.post(url, formData )
      .then(function (response) {
        console.log(response);
        navigate('/dashboard')
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  return (
    <div>
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={onSubmit}>
              <p>{t('articles.form.upload_image_for')}</p>
              <p className='text-center text-2xl'> "{state.article.title}"</p>
              <br />

              <input type="file" {...register('file')} />
              <br />
              <br />
              <button
                type='submit'
                className='flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
              {t('articles.form.load_image')}
              </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UploadImage;
