import { useEffect, useState } from 'react';
import axios from 'axios';

const URL_BASE = 'http://192.168.0.82:3000';
// https://codefithub.onrender.com

const Setting = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    axios.get(URL_BASE + '/settings/get_gck')
      .then(response => {
        console.log('useEffect');
        setConfig(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the config!', error);
      });
  }, []);

  return (
    <div className='container mx-auto'>
      <h1 className='text-2xl font-bold mb-4'>JSON Viewer</h1>
      <div className='bg-white shadow-md rounded-lg p-6'>
        <pre id='json-output' className='text-gray-800 whitespace-pre-wrap break-words'>
          {JSON.stringify(config, null, 2)}
        </pre>
      </div>
    </div>
  );
}

export default Setting;
