import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    } else {
      i18n.changeLanguage('es');
      localStorage.setItem('language', 'es');
    }
  }, [i18n]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <div>
      <button onClick={() => changeLanguage('es')}>
        <img
          src={require('../assets/images/spanish-flag.png')}
          alt=''
          className='rounded-md'
          style={{ width: '25px', height: '25px' }}
        />
      </button>
      <button onClick={() => changeLanguage('en')}>
        <img
          src={require('../assets/images/english-flag.png')}
          alt=''
          className='rounded-md'
          style={{ width: '25px', height: '25px' }}
        />
      </button>
      <button onClick={() => changeLanguage('pt')}>
        <img
          src={require('../assets/images/brazil-flag.png')}
          alt=''
          className='rounded-md'
          style={{ width: '25px', height: '25px' }}
        />
      </button>
      <button onClick={() => changeLanguage('fr')}>
        <img
          src={require('../assets/images/france-flag.png')}
          alt=''
          className='rounded-md'
          style={{ width: '25px', height: '25px' }}
        />
      </button>
    </div>
  );
};

export default LanguageSelector;
