import {
  BrowserRouter as Router,
} from "react-router-dom";

import BlogRoutes from './Routes';

import React from 'react';

import Navbar from './Navbar';
import { AuthProvider } from './auth';

const Menu: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div>
          {/* <header className="bg-white"> */}
          {/* <!-- Navbar --> */}
            <Navbar></Navbar>
          {/* <!-- Navbar --> */}
          {/* </header> */}

          {/* A <Routes> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <BlogRoutes></BlogRoutes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default Menu;
