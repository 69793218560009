import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type UserInfoType = {
  username: string;
  email: string;
  bio: string;
};

const URL_BASE = 'https://codefithub.onrender.com';

const ProfileShow = () => {
  const [data, setData] = useState<UserInfoType>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: URL_BASE + '/user',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          },
        }

        axios.request<UserInfoType>(configuration).then(
          (res) => {
            setData(res.data)
          }
          ).catch((err) => {
            navigate('/login');
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [navigate]); // Empty dependency array means this effect runs once on mount

  return (
    <section className="mb-40">
      {/* <!-- Jumbotron --> */}
      <div className="flex flex-col justify-center items-center">
        <div className="relative flex flex-col items-center rounded-[20px] w-[700px] max-w-[95%] mx-auto bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3">
          <div className="mt-2 mb-8 w-full">
            <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
              {t('profile.general_information')}
            </h4>
          </div>
          <div className="grid grid-cols-2 gap-4 px-2 w-full">
            <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">{t('profile.username')}</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                  {data?.username}
              </p>
            </div>

            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">{t('profile.email')}</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                  {data?.email}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 px-2 w-full">
            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">{t('profile.bio')}</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                  {data?.bio}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default ProfileShow
