import {
  Routes,
  Route
} from "react-router-dom";

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../utlis/analytics';

import About from './About';
import Login from './Login';
// import Signup from './Signup';
import Home from './Home';
import Dashboard from './Dashboard';
import Contact from './Contact';
import ContactMessages from './ContactMessages';
import Profile from './Profile';
import Logout from './Logout';
import { AuthProvider } from './auth';
import RequireAuth from './RequireAuth';
import ArticleEditForm from './ArticleEditForm';
import ArticleAddForm from './ArticleAddForm';
import PublishedArticles from './PublishedArticles';
import PublishedArticleShow from './PublishedArticleShow';
import Setting from './Setting';
import FourZeroFour from './FourZeroFour';

import UploadImage from './UploadImage';

const BlogRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/signup" element={<Signup />} /> */}
      <Route path="/about" element={<About />} />
      <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />

      <Route path="/published-articles" element={<PublishedArticles />}/>
      <Route path="/settings/get_gck" element={<Setting />}/>
      <Route path="/upload-image/:id" element={<UploadImage />}/>
      <Route path="/article/edit/:id" element={<RequireAuth><ArticleEditForm /></RequireAuth>}/>
      <Route path="/article/:id/show" element={<PublishedArticleShow />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/contact/messages" element={<ContactMessages />}/>
      <Route path="/article/new" element={<RequireAuth><ArticleAddForm /></RequireAuth>}/>
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<FourZeroFour />} />
    </Routes>
  );
};

export default BlogRoutes;
