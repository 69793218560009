export const textSize = {
  titleMinSize: 10,
  titleMaxSize: 100,
  subheadingMinSize: 120,
  subheadingMaxSize: 500,
  contentMinSize: 100,
  contentMaxSize: 15000,
}

export const alertMessages = {
  textTooShort: 'Texto muy corto',
  textTooLong: 'Texto muy largo',
  fieldRequired: 'Campo requerido'
}
