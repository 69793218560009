import { useState } from 'react';
import ProfileShow from './ProfileShow';
import ProfileEditForm from './ProfileEditForm';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const [showForm, setShowForm] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
  const { t } = useTranslation();

  const toggleForm = () => {
    setShowForm(!showForm);
    setShowProfile(!showProfile);
  };

  return (
    <div className="container mx-auto">
      <div className="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
      <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
        <div onClick={toggleForm}>
          {showForm ?
            <button className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              {t('profile.show_profile')}
            </button>
            :
              <button className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">
                {t('profile.edit_profile')}
            </button>
            }
        </div>
        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          {
            showForm && (
              <ProfileEditForm></ProfileEditForm>
          )}
        </div>

        {showProfile && (<ProfileShow></ProfileShow>)}
      </div>
      </div>
    </div>
  )
}

export default Profile;
