import Layout from './components/Layout';
import { initGA, logPageView } from './utlis/analytics';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <div>
      <Layout>
      </Layout>
    </div>
  );
}

export default App;
