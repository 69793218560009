import { useState, useEffect } from 'react';
import axios from 'axios';
import PublishedArticle from './PublishedArticle';
import SEO from './SEO';

export type ArticleType = {
  _id: string;
  title: string;
  video?:  string | null;
  content?: string | null;
  image: string;
  publeshed: boolean;
  published_at: string;
};

type AllDataType = {
  articles: ArticleType[]; // Assuming ArticleType is another type you've defined
};

const URL_BASE = 'https://codefithub.onrender.com';

const PublishedArticles = () => {
  const [data, setData] = useState<AllDataType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: URL_BASE + '/published-articles',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        }

        axios.request<AllDataType>(configuration).then(
          (res) => {

            const sortArticlesByDate = (articles: ArticleType[]): ArticleType[] => {
              return articles.sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime());
            };

            if (res.data && res.data.articles) {
              const sortedArticles = sortArticlesByDate(res.data.articles);
              setData({ ...res.data, articles: sortedArticles });
            }
          }
          ).catch((err) => {
            console.error(err);
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="container mx-auto space-y-6">
      <SEO
        title='AnittaMendezFitness: Ejercicios,Rutinas,Circuitos,Salud,Bienestar'
        description='Descubre cómo mejorar tu salud con circuitos y rutinas de ejercicio y motivación para lograr tus objetivos de fitness.'
        image='https://anittamendezfitness.com/static/media/logo-fitness.e7fde95318d7ca1fc76f.png'
        url='https://anittamendezfitness.com/published-articles'
      />
        {data?.articles.map(article => (
          <div className="relative overflow-x-auto shadow-2xl sm:rounded-lg mb-6 bg-gray-100">
            <PublishedArticle article={article} imageURL={article.image} />
          </div>
        ))}
    </div>
  )
}

export default PublishedArticles
