import axios, { AxiosResponse, AxiosError } from 'axios';
import { ResultType, ArticleInfoType } from '../../types/ArticleTypes';

type ResultArticleType = {
  article: ArticleInfoType | undefined;
  status: ResultType;
};

const URL_BASE = 'https://codefithub.onrender.com';

class Query {
  title: string | undefined;
  subheading: string | undefined;
  video: string | undefined;
  content: string | undefined;
  published: boolean | undefined;
  published_at: string | undefined;
  url: string;
  verb: string;

  constructor(url: string, verb: string, title?: string, subheading?: string, video?: string, content?: string, published?: boolean) {
    this.title = title;
    this.subheading = subheading;
    this.video = video;
    this.content = content;
    this.published = published;
    this.published_at = new Date().toString();
    this.url = url;
    this.verb = verb;
  }

  // private configuration(): Hash {
  //   let config: any  = {};
  //   config = {
  //     url: this.url,
  //     method: this.verb,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
  //     },
  //   }
  //   if (this.title !== undefined || this.content !== undefined) {
  //     config.data = {
  //       title: this.title,
  //       content: this.content
  //     }
  //   }
  //   return config;
  // }

  get(): Promise<ResultArticleType> {

     const configuration = {
      url: URL_BASE + this.url,
      method: this.verb,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
    }

    return new Promise((resolve, reject) => {
      axios.request(configuration).then((res: AxiosResponse) => {
        if (res.data.error) {
          resolve({ article: undefined, status: {message: res.data.error, status: 'Data failure' }});
        } else {
          resolve({ article: res.data, status: {message: res.data.error, status: 'Ok' } });
        }
      }).catch((err: AxiosError) => {
        reject({ message: err, status: 'System failure' });
      })
    });
  }

  postOrPatch(): Promise<ResultType> {

    const configuration = {
      url: URL_BASE + this.url,
      method: this.verb,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
      data: {
        title: this.title,
        subheading: this.subheading,
        video: this.video,
        content: this.content,
        published: this.published,
        published_at: this.published_at,
      },
    }

    return new Promise((resolve, reject) => {

      axios.request(configuration).then((res: AxiosResponse) => {
        if (res.data.error) {
          resolve({ message: res.data.error, status: 'Data failure' });
        } else {
          let message = this.verb === 'post' ? 'Article created!' : 'Article updated!';
          resolve({ message: message, status: 'ok' });
        }
      }).catch((err: AxiosError) => {
        reject({ message: err, status: 'System failure' });
      })
    });
  }

  updatePublishedStatus(): Promise<ResultType> {
    const configuration = {
      url: URL_BASE + this.url,
      method: this.verb,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
      data: {
        published: this.published
      },
    }

    return new Promise((resolve, reject) => {

      axios.request(configuration).then((res: AxiosResponse) => {
        if (res.data.error) {
          resolve({ message: res.data.error, status: 'Data failure' });
        } else {
          let message = this.published ? 'Published!' : 'Not published!';
          resolve({ message: message, status: 'ok' });
        }
      }).catch((err: AxiosError) => {
        reject({ message: err, status: 'System failure' });
      })
    });
  }
}

export default Query;
