import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Query from '../requests/Articles/Query';
import { ResultType } from '../types/ArticleTypes';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type ArticleType = {
  _id: string;
  subheading: string;
  title: string;
  video?: string | null;
  content?: string | null;
  published: boolean;
  published_at: string;
  image: string;
};

type AllDataType = {
  articles: ArticleType[]; // Assuming ArticleType is another type you've defined
};

const URL_BASE = 'https://codefithub.onrender.com';

const Article = () => {
  const [data, setData] = useState<AllDataType>({articles: []});
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: URL_BASE + '/articles',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          },
        }

        axios.request<AllDataType>(configuration).then(
          (res) => {
            setData(res.data)
          }
          ).catch((err) => {
            navigate('/login');
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [navigate]); // Empty dependency array means this effect runs once on mount

  const handleDelete = (id: string) => {

    Swal.fire({
      title: 'Cuidado',
      text: 'Quieres continuar y borrar el artículo?',
      icon: 'warning',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `/article/delete/${id}`;

        const configuration = {
          url: URL_BASE + url,
          method: 'delete',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          },
          data: {
            id
          },
        }

        axios.request(configuration)
          .then(_result => {
            const updatedArticles: AllDataType = {
              articles: data!.articles.filter((article) => article._id !== id
              )
            };
            setData(updatedArticles);
          })
          .catch(error => {
            console.log(error);
          });
        Swal.fire('Borrado!', 'El artículo no borró.', 'success');
      }
      else {
        Swal.fire('No se borró!', 'El artículo no se borró.', 'info');
      }
    })
  }

  const handlePublishArticle = (to_publish: string, articleId: string) => {
    const url = `/article/${articleId}/status`;

    const title = undefined;
    const subheading = undefined;
    const video = undefined;
    const content = undefined;
    const published = to_publish === 'true' ? true : false;
    const verb = 'patch';

    const query = new Query(url, verb, title, subheading, video, content, published);
      query.updatePublishedStatus().then((result: ResultType) => {
        setData((prevData) => ({
          ...prevData,
          articles: prevData.articles.map((article) =>
            article._id === articleId
              ? { ...article, published: !article.published }
              : article
          ),
        }));
      })
        .catch((error) => {
        console.error(error);
      });
  }

  const publishedAt = (published_at: string): string => {
    const fecha = new Date(published_at);

    const day = fecha.getDate();
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();

    // Formatear la fecha como "dd/mm/yyyy"
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
  }

  return (
      <div className="container mx-auto">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="p-4">
            <label className="sr-only">Search</label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              <input type="text" id="table-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('dashboard.search_for_items')}></input>
              </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    {t('dashboard.title')}
                  </th>
                  <th>
                    {t('dashboard.published')}
                  </th>
                  <th>
                    {t('dashboard.published_at')}
                </th>
                <th>
                  {t('dashboard.image')}
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  {t('dashboard.actions')}
                </th>
                </tr>
              </thead>
            <tbody>
              {data?.articles.map(article => (
                <tr
                className='even:bg-cian-100 odd:bg-gray-100'
                  key={article._id}
                >
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-black whitespace-nowrap">
                    {article.title.slice(0, 30)}
                  </th>
                  <th>
                      {
                        article.published ?
                          <div className="flex items-center me-4">
                            <label className="ms-2 text-sm font-semibold text-green-900 dark:text-green-300">
                              {t('answer.yes')}
                            </label>
                          </div>
                          :
                          <div className="flex items-center me-4">
                            <label className="ms-2 text-sm font-semibold text-red-900 dark:text-red-300">
                              {t('answer.no')}
                            </label>
                        </div>
                      }
                  </th>
                  <th>
                      {
                        article.published_at && article.published ?
                        <div className="flex items-center me-4">
                          {publishedAt(article.published_at)}
                        </div>
                          :
                        <div className="flex items-center me-4">
                          No Data
                        </div>
                      }
                  </th>
                  <th>
                    {
                      article.image ?
                        <Link className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-red-900 hover:bg-red-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to={`/upload-image/${article._id}`} state={{ article }}>
                          {t('dashboard.change_image')}
                        </Link>
                        :
                        <Link className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-green-900 hover:bg-gray-50" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to={`/upload-image/${article._id}`} state={{ article }}>
                          {t('dashboard.upload_image')}
                        </Link>
                    }
                  </th>
                  <th className="px-6 py-4 text-center">
                    <div className='space-y-1'>
                      <Link
                        className='focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900'
                        to={`/article/edit/${article._id}`} state={{ article }}>
                          {t('dashboard.edit')}
                      </Link>
                      <button
                        className='focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900'
                        onClick={() => {handleDelete(article._id)}}
                        >
                          {t('dashboard.delete')}
                      </button>

                      <div className="inline-flex rounded-md shadow-sm" role="group">
                        <button
                          disabled = { article.published ?  false : true}
                          type="button"
                          className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                          onClick={()=>handlePublishArticle('false', article._id)}
                        >
                          {t('dashboard.hide')}
                        </button>
                        <button
                          disabled = { article.published ? true : false}
                          type="button"
                          className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                          onClick={()=>handlePublishArticle('true', article._id)}
                        >
                          {t('dashboard.publish')}
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
            ))}
              </tbody>
            </table>
          </div>

        </div>
    )
}

export default Article
