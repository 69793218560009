import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth';


const Dashboard = () => {
  const navigate = useNavigate();
  const auth = useAuth();


  useEffect(() => {
    localStorage.removeItem('jwt');
    auth?.logout();
    navigate('/home');

  });

  return (
    <div>
      <span>Login Out ...</span>
    </div>
    )
}

export default Dashboard
