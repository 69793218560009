import Article from './Article';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto">
      <div className="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
      {/* <!-- Jumbotron --> */}
        <Link
          className='focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900'
          to={`/article/new`}>
          {t('dashboard.new')}
        </Link>

        <Article />
      {/* <!-- Jumbotron --> */}
      </div>
    </div>
    )
}

export default Dashboard
