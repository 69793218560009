import { useState, useEffect } from 'react';
import axios from 'axios';
import SEO from './SEO';

export type UserInfoType = {
  bio: string;
};
const URL_BASE = 'https://codefithub.onrender.com';

const Home = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<UserInfoType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: URL_BASE + '/user/bio',
          method: 'get',
        }

        axios.request<UserInfoType>(configuration).then(
          (res) => {
            setData(res.data)
          }
          ).catch((err) => {
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount
  return (
    <div className='container mx-auto'>
      <SEO
        title='AnittaMendezFitness: Ejercicios,Rutinas,Circuitos,Salud,Bienestar'
        description='Descubre cómo mejorar tu salud con circuitos y rutinas de ejercicio y motivación para lograr tus objetivos de fitness.'
        image='https://anittamendezfitness.com/static/media/logo-fitness.e7fde95318d7ca1fc76f.png'
        url='https://anittamendezfitness.com/home'
      />
      <div className='relative isolate px-6 pt-14 lg:px-8'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='w-4/5 mx-auto'>
            <div className='hidden sm:mb-8 sm:flex sm:justify-center'>
              <div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                ¡Descubre inspiración en cada palabra! ¡Explora mis artículos, despierta nuevas ideas!.{' '}
                <a href='published-articles' className='font-semibold text-indigo-600'>
                  <span className='absolute inset-0' aria-hidden='true' />
                  Leer mas <span aria-hidden='true'>&rarr;</span>
                </a>
              </div>
            </div>
            <div className='text-center'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
                ¡Activa tu vida, transforma tu salud! ¡Ejercítate para un mejor tú!
              </h1>
              <p className='mt-6 text-lg leading-8 text-gray-600'>
                ¡Claro! Comienza tu viaje hacia una vida más saludable y en forma. Cada paso y cada esfuerzo cuenta. Con cada sesión de ejercicio, no solo fortaleces tu cuerpo, sino también tu mente. Descubre el poder transformador de cuidar de ti mismo, alcanzando metas que te harán sentir más enérgico, centrado y feliz. ¡Tú mereces invertir en tu bienestar! ¡Vamos, tú puedes hacerlo!
              </p>
              <div className='mt-10 flex items-center justify-center gap-x-6'>
                <a
                  href='/published-articles'
                  className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  Comienza
                </a>
                <a href='/about' className='text-sm font-semibold leading-6 text-gray-900'>
                  Aprender más <span aria-hidden='true'>→</span>
                </a>
              </div>
            </div>
          </div>
          <div className='w-full mx-auto'>
            <img
              src={require('../assets/images/home_1.jpg')}
              alt=''
              className='rounded-md w-full h-auto object-contain'
            />
          </div>
        </div>
        <div
          className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff809e] to-[#277cd1] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Home
