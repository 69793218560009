import { useState, useContext, createContext, ReactNode } from 'react';

export type UserInfoType = {
  username: string;
  email: string;
};

interface AuthContextType {
  user: UserInfoType | null;
  login: (user: UserInfoType | null) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface Props {
    children?: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<UserInfoType | undefined>();
  const login = (user: UserInfoType): void => {
    setUser(user);
  }

  const logout = (): void => {
    localStorage.removeItem('jwt');
    setUser(undefined);
  }

  const authContextValue = { user, login, logout } as AuthContextType;

  return (
    <AuthContext.Provider value={authContextValue}>
    {children}
    </AuthContext.Provider>
  )
};

// Custom hook to access the context without wrapping components in the provider
export const useAuth = () => {
  return useContext(AuthContext);
}
