import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './auth';

interface Props {
    children?: ReactNode
}

const RequireAuth = ({ children }: Props) => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth?.user) {
    return <Navigate to={'/login'} state={{ path: location.pathname }} />;
  }


  return (
    <>
      {children}
    </>
  );
}

export default RequireAuth;
