import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArticleForm from './ArticleForm';

import Query from '../requests/Articles/Query';
import { ResultType } from '../types/ArticleTypes';
import { useNavigate, useLocation } from 'react-router-dom';
import { alertMessages, textSize } from '../alerts/Messages';

const schema = Yup.object().shape({
  title: Yup.string().min(textSize.titleMinSize, alertMessages.textTooShort).max(textSize.titleMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
  subheading: Yup.string().min(textSize.subheadingMinSize, alertMessages.textTooShort).max(textSize.subheadingMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
  video: Yup.string().min(textSize.contentMinSize, alertMessages.textTooShort).max(textSize.contentMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
  content: Yup.string().min(textSize.contentMinSize, alertMessages.textTooShort).max(textSize.contentMaxSize, alertMessages.textTooLong).required(alertMessages.fieldRequired),
});



const ArticleEditForm: React.FC = () => {
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();

  const url = `/article/${state.article._id}`;

  const formik = useFormik({
    initialValues: { title: state.article.title, subheading: state.article.subheading, video: state.article.video, content: state.article.content },
    onSubmit: (values) => {
      const title = values.title;
      const subheading = values.subheading;
      const video = values.video;
      const content = values.content;
      const verb = 'patch';

      const query = new Query(url, verb, title, subheading, video, content);
        query.postOrPatch()
          .then((result: ResultType) => {
            if (result.status === 'ok') {
              setTimeout(() => {
                navigate('/dashboard');
              }, 2000);
            }
        })
          .catch((error) => {
          console.error(error);
        });
    },
    validationSchema: schema
  });

  return (
    <div className="container mx-auto">
      <div className="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
        {/* <!-- Jumbotron --> */}
        <h1>Edit Article</h1>
        <ArticleForm formik={formik} />
      </div>
    </div>
    )
}

export default ArticleEditForm
