import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type ContactMessageType = {
  _id: string;
  username: string;
  email: string;
  content: string;
  created_at: string;
};

type AllDataType = {
  messages: ContactMessageType[]; // Assuming ContactMessageType is another type you've defined
};

const URL_BASE = 'https://codefithub.onrender.com';

const ContactMessages = () => {
  const [data, setData] = useState<AllDataType>({messages: []});
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuration = {
          url: URL_BASE + '/contact/messages',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          },
        }

        axios.request<AllDataType>(configuration).then(
          (res) => {
            setData(res.data)
          }
        ).catch((err) => {
          console.log(err);
            navigate('/home');
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [navigate]); // Empty dependency array means this effect runs once on mount

  const createdAt = (published_at: string): string => {
    const fecha = new Date(published_at);

    const day = fecha.getDate();
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();

    // Formatear la fecha como "dd/mm/yyyy"
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
  }

  return (
      <div className="container mx-auto">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t('contact.username')}
                </th>
                <th>
                  Email
                </th>
                <th>
                  {t('contact.content')}
                </th>
                <th>
                  {t('contact.created_at')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.messages.map(message => (
                <tr
                className='even:bg-cian-100 odd:bg-gray-100'
                  key={message._id}
                >
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-black whitespace-nowrap text-left align-top p-4 border-b">
                    {message.username}
                  </th>
                  <th className='text-blue-800 text-left align-top p-4 border-b'>
                    {message.email}
                  </th >
                  <th className="px-6 py-4 font-medium text-gray-900 dark:text-black text-left align-top p-4 border-b">
                    {message.content}
                  </th>
                  <th className="px-6 py-4 font-medium text-gray-900 dark:text-black text-left align-top p-4 border-b">
                    {createdAt(message.created_at)}
                  </th>

                </tr>
            ))}
              </tbody>
            </table>
          </div>

        </div>
    )
}

export default ContactMessages
